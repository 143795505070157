var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" },
    [
      _c(
        "c-table",
        {
          ref: "table",
          attrs: {
            title: "검진소견 목록",
            tableId: "table",
            columns: _vm.grid.columns,
            gridHeight: _vm.grid.height,
            merge: _vm.grid.merge,
            data: _vm.grid.data,
            filtering: true,
            columnSetting: false,
            usePaging: false,
            isExcelDown: false,
            isFullScreen: false,
            expandAll: true,
            rowKey: "heaCheckupOpinionId",
          },
          on: { linkClick: _vm.linkClick },
        },
        [
          _c(
            "template",
            { slot: "table-button" },
            [
              _c(
                "q-btn-group",
                { attrs: { outline: "" } },
                [
                  _c("c-btn", {
                    attrs: { label: "검진소견서 업로드", icon: "upload" },
                    on: { btnClicked: _vm.openExcelUploader },
                  }),
                  _c("c-btn", {
                    attrs: { label: "전체 삭제", icon: "delete_forever" },
                    on: { btnClicked: _vm.removeAll },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "template",
            { slot: "table-chip" },
            [
              _c("font", { attrs: { color: "#C10015" } }, [
                _vm._v(
                  " ※ 추가로 엑셀업로드 시 기존데이터에 결과가 추가됩니다(중복 허용). 다시 업로드 하고 싶으면 전체 삭제 후 진행하세요. "
                ),
              ]),
            ],
            1
          ),
        ],
        2
      ),
      _c("c-dialog", { attrs: { param: _vm.popupOptions, isConfirm: true } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }